<template>
  <v-container> Articles </v-container>
</template>

<script>
export default {
  name: 'Articles',

  data: () => ({}),

  watch: {},

  computed: {},

  methods: {}
}
</script>

<style scoped>
</style>
